import { IconMapChateauFort } from '@/components/icon/IconMapChateauFort';
import { IconMapCrypte } from '@/components/icon/IconMapCrypte';
import { IconMapEglise } from '@/components/icon/IconMapEglise';
import { IconMapMairie } from '@/components/icon/IconMapMairie';
import { IconMapPaysage } from '@/components/icon/IconMapPaysage';
import { IconMapRuine } from '@/components/icon/IconMapRuine';
import { IconMapStatue } from '@/components/icon/IconMapStatue';
import { IconMapOfficeTourisme } from '@/components/icon/IconMapPinOfficeTourisme';
import { IconMapAbbaye } from '../../icon/IconMapAbbaye';
import { CapsulesIcons } from '../../../models/MapBox';
import Theme from '../../../styles/Theme';

interface Props {
	size: number;
	icon: CapsulesIcons;
	color: string;
}

export const CapsuleIcon = ({ icon, size = 32, color = Theme.white }: Props) => (
	<>
		{ icon === 'abbaye' && <IconMapAbbaye width={size} height={size} color={color}/> }
		{ icon === 'eglise' && <IconMapEglise width={size} height={size} color={color}/> }
		{/* { icon === 'larme' && <MapLarmeSVG width={size} height={size} color={color}/> } */}
		{ icon === 'office-tourisme' && <IconMapOfficeTourisme width={size} height={size} color={color}/> }
		{ icon === 'paysage' && <IconMapPaysage width={size} height={size} color={color}/> }
		{/* { icon === 'piano' && <MapPianoSVG width={size} height={size} color={color}/> } */}
		{ icon === 'ruine' && <IconMapRuine width={size} height={size} color={color}/> }
		{ icon === 'statue' && <IconMapStatue width={size} height={size} color={color}/> }
		{/* { icon === 'visage' && <MapVisageSVG width={size} height={size} color={color}/> } */}

		{ icon === 'mairie' && <IconMapMairie width={size} height={size} color={color}/> }
		{ icon === 'crypte' && <IconMapCrypte width={size} height={size} color={color}/> }
		{ icon === 'chateau-fort' && <IconMapChateauFort width={size} height={size} color={color}/> }
	</>
);
