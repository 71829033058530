import {
	BBox, Feature, Point,
} from 'geojson';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import {
	FunctionComponent, PropsWithChildren, useCallback, useEffect, useRef, useState,
} from 'react';
import Map, {
	MapRef, AttributionControl,
	GeolocateResultEvent, GeolocateErrorEvent, GeolocateControl,
} from 'react-map-gl';
import styled from 'styled-components';
import { ClusterProperties } from 'supercluster';
import useSupercluster from 'use-supercluster';

import PointerActiveSVG from '../../assets/map/pointer-active.svg';
import PointerSVG from '../../assets/map/pointer.svg';
import Theme from '../../styles/Theme';
import { MapStore } from '../../data/MapStore';
import { MAPBOX_KEY } from '../../env';
import { Capsule } from '../../models/Capsule';

import { MapClusterMarker } from './MapClusterMarker';
import { MapPin } from './MapPin';

import 'mapbox-gl/dist/mapbox-gl.css';

interface Props {
	capsules: Feature<Point, Capsule>[];
	forceLocation?: boolean;

	disablePinClick?: boolean;
}

// const DynamicDirections = dynamic(() => import('./Directions'), {
// 	ssr: false,
// });

const StyledMapContainer = styled.div<{heightOffsetButtons: number}>`
	position: relative;
	flex: 1;

	.mapboxgl-ctrl-bottom-left {
		/* bottom: ${(props) => props.heightOffsetButtons}px; */
		z-index: 0;
	}
	.mapboxgl-ctrl-bottom-right {
		bottom: ${(props) => props.heightOffsetButtons}px;
	}

	.mapboxgl-ctrl button:not(:disabled):hover {
		background-color: transparent;
	}

	.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate {
		color: ${(props) => props.theme.black};
	}

	.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
		background-image: url(${PointerSVG.src});
	}

	.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active .mapboxgl-ctrl-icon {
		background-image: url(${PointerActiveSVG.src});
	}
`;

const maxZoom = 18;
// TODO:
// 1. Define a MaxZoom per client
// 2. Define a Zoom per Parcours

const maxBounds: LngLatBoundsLike = [
	[-6.0, 40.0], // Southwest coordinates
	[13.5, 52.0], // Northeast coordinates
];

export const MapBox: FunctionComponent<PropsWithChildren<Props>> = ({
	capsules, children, forceLocation = false, disablePinClick = false,
}: PropsWithChildren<Props>) => {
	const {
		parcoursSelected, capsuleSelected, display, heightOffsetButtons,
	} = MapStore.useState((s) => s);
	const [viewState, setViewState] = useState({
		longitude: 2.209667,
		latitude:  46.232193,
		zoom:      5,
	});
	const mapRef = useRef<MapRef>(null);
	const [mapLoaded, setMapLoaded] = useState(false);

	const bounds = mapRef.current
		? mapRef.current
			.getMap()
			.getBounds()
			.toArray()
			.flat()
		: null;

	const { clusters, supercluster } = useSupercluster<Capsule, Capsule>({
		points:  capsules,
		bounds:  bounds as BBox || undefined,
		zoom:    viewState.zoom,
		options: { radius: 50, maxZoom: 14 },
	});

	// const [popupInfo, setPopupInfo] = useState<MarkerInfos | null>(null);

	const onClusterClick = useCallback((
		longitude: number,
		latitude: number,
		// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
		_cluster: supercluster.PointFeature<Capsule>,
	) => {
		if (supercluster) {
			// const expansionZoom = Math.min(
			// 	supercluster.getClusterExpansionZoom((cluster as { id: number }).id),
			// 	20,
			// );

			mapRef.current?.flyTo({
				center: [longitude, latitude],
				zoom:   17,
				speed:  2,
			});
		}
	}, [supercluster]);

	const onCapsuleClick = useCallback((capsuleProperties: Capsule) => {
		if (!disablePinClick && display === 'pin') {
			MapStore.update((s) => {
				if (capsuleProperties.attributes.slug === s.capsuleSelected?.attributes.slug) {
					s.capsuleSelected = null;
				} else {
					s.capsuleSelected = capsuleProperties;
				}
			});
		}

		// mapRef.current?.flyTo({
		// 	center: [longitude, latitude],
		// 	zoom:   10,
		// 	speed:  2,
		// });
	}, [disablePinClick, display]);

	// To force the map to rerender when the markers changed
	const [, updateState] = useState<unknown>();
	useEffect(() => {
		updateState({});
	}, [supercluster, clusters.length]);

	const onLoad = useCallback(() => {
		mapRef.current?.resize();
		setMapLoaded(true);
	}, []);

	const onGeolocate = useCallback((event: GeolocateResultEvent) => {
		MapStore.update((s) => {
			s.userLocation = event.coords;
			s.userLocationError = 0;
		});
	}, []);

	const onGeolocateError = useCallback((error: GeolocateErrorEvent) => {
		MapStore.update((s) => {
			s.userLocation = null;
			s.userLocationError = error.code;
		});
	}, []);

	const geolocateControlRef = useCallback((ref: mapboxgl.GeolocateControl) => {
		if (ref && mapLoaded && forceLocation) {
			ref.trigger(); // Trigger the user location
		}
	}, [forceLocation, mapLoaded]);

	return (
		<StyledMapContainer heightOffsetButtons={heightOffsetButtons}>
			<Map
				{...viewState}
				minZoom={4}
				maxZoom={maxZoom}
				mapboxAccessToken={MAPBOX_KEY}
				mapLib={mapboxgl}
				mapStyle="mapbox://styles/pleinairmusair/cl7c5q6df004j15nqetihjed6"
				onMove={(evt) => { setViewState(evt.viewState); }}
				ref={mapRef}
				attributionControl={false}
				onLoad={onLoad}
				maxBounds={maxBounds}
				// logoPosition='top-left'
			>
				{ clusters && clusters.length > 0 && supercluster && clusters.map((cluster) => {
					const [longitude, latitude] = cluster.geometry.coordinates;

					const {
						cluster: isCluster,
						point_count: nbPoint,
					} = cluster.properties as ClusterProperties;

					if (isCluster) {
						return (
							<MapClusterMarker
								key={`cluster-${cluster.id}`}
								latitude={latitude}
								longitude={longitude}
								nbPoint={nbPoint}
								totalPoint={(capsules || []).length}
								backgroundColor={parcoursSelected === null
									? Theme.mapDirectionText : Theme.mapOrange}
								onClick={() => onClusterClick(longitude, latitude, cluster)}
							/>
						);
					}

					let justOneSelected = false;
					let color = Theme.mapDirectionText;
					if (capsuleSelected
						&& cluster.properties.attributes.slug === capsuleSelected.attributes.slug) {
						color = Theme.mapOrange;
						justOneSelected = true;
					} else if (!capsuleSelected
						&& cluster.properties.attributes.route.data.attributes.slug === parcoursSelected) {
						color = Theme.mapOrange;
					}

					return (
						<MapPin
							key={`pin-${cluster.properties.attributes.slug}`}
							longitude={longitude}
							latitude={latitude}
							color={color}
							icon={cluster.properties.attributes.icon}
							onClick={() => onCapsuleClick(cluster.properties)}
							selected={justOneSelected}
						/>
					);
				})}

				<AttributionControl compact={true} position={'top-right'} />

				<GeolocateControl
					ref={geolocateControlRef}
					trackUserLocation={true}
					showUserHeading={true}
					positionOptions={{
						enableHighAccuracy: true,
					}}
					position="bottom-right"
					style={{
						width:          48,
						height:         48,
						borderRadius:   '48px',
						boxShadow:      '0px 1px 2px rgba(0, 0, 0, 0.16)',
						display:        'flex',
						alignItems:     'center',
						justifyContent: 'center',
					}}
					onGeolocate={onGeolocate}
					onError={onGeolocateError}
				/>
			</Map>
			{ mapLoaded && <>
				{ children }
			</>}
		</StyledMapContainer>
	);
};
