import useSWR, { SWRResponse } from 'swr';
import qs from 'qs';
import { httpGet } from '../lib/fetch';
import { Capsule } from '../models/Capsule';

import { StrapiGet } from './Strapi';

export function useCapsulesFromClientId(
	clientId: number,
	routeSlug: string | null,
	locale: string,
) {
	const query = qs.stringify({
		filters: {
			client: {
				id: {
					$eq: clientId,
				},
			},
			route: routeSlug ? {
				slug: {
					$eq: routeSlug,
				},
			} : undefined,
		},
		populate: ['route', 'images', 'audio'],
		sort:     'position',
	}, {
		encodeValuesOnly: true,
	});

	return useSWR<Capsule[] | null>(
		[`/capsules?${query}`, locale],
		async ([url, localeSWR]) => {
			const data = await httpGet<StrapiGet<Capsule[]>>(url);

			return data.data.map((capsule) => {
				if (localeSWR === 'en') {
					if (capsule.attributes.titleEn && capsule.attributes.titleEn.length > 0) {
						capsule.attributes.title = capsule.attributes.titleEn;
					}
					if (capsule.attributes.subTitleEn && capsule.attributes.subTitleEn.length > 0) {
						capsule.attributes.subTitle = capsule.attributes.subTitleEn;
					}
					if (capsule.attributes.audioEn && capsule.attributes.audioEn.data !== null) {
						capsule.attributes.audio = capsule.attributes.audioEn;
					}
				}
				return capsule;
			});
		},
	);
}

export function useCapsulesFromRouteSlug(
	routeSlug: string | null,
	locale: string,
): SWRResponse<Capsule[] | null, Error> {
	const query = qs.stringify({
		filters: {
			route: {
				slug: {
					$eq: routeSlug,
				},
			},
		},
		populate: ['route', 'images', 'audio'],
		sort:     'position',
	}, {
		encodeValuesOnly: true,
	});

	return useSWR<Capsule[] | null>(
		[`/capsules?${query}`, locale],
		async ([url, localeSWR]) => {
			if (!routeSlug) {
				return [];
			}

			const data = await httpGet<StrapiGet<Capsule[]>>(url);

			return data.data.map((capsule) => {
				if (localeSWR === 'en') {
					if (capsule.attributes.titleEn && capsule.attributes.titleEn.length > 0) {
						capsule.attributes.title = capsule.attributes.titleEn;
					}
					if (capsule.attributes.subTitleEn && capsule.attributes.subTitleEn.length > 0) {
						capsule.attributes.subTitle = capsule.attributes.subTitleEn;
					}
					if (capsule.attributes.audioEn && capsule.attributes.audioEn.data !== null) {
						capsule.attributes.audio = capsule.attributes.audioEn;
					}
				}
				return capsule;
			});
		},
	);
}

export function useCapsule(capsuleSlug: string, locale: string) {
	const query = qs.stringify({
		filters: {
			slug: {
				$eq: capsuleSlug,
			},
		},
		populate: ['route', 'images', 'audio', 'audioEn', 'capsule_last_slide'],
	}, {
		encodeValuesOnly: true,
	});

	return useSWR<Capsule | null>(
		[`/capsules?${query}`, locale],
		async ([url, localeSWR]) => {
			const data = await httpGet<StrapiGet<Capsule[]>>(url);

			if (data.data.length === 0) {
				return null;
			}

			const capsule = data.data[0];

			if (localeSWR === 'en') {
				if (capsule.attributes.titleEn && capsule.attributes.titleEn.length > 0) {
					capsule.attributes.title = capsule.attributes.titleEn;
				}
				if (capsule.attributes.subTitleEn && capsule.attributes.subTitleEn.length > 0) {
					capsule.attributes.subTitle = capsule.attributes.subTitleEn;
				}
				if (capsule.attributes.audioEn && capsule.attributes.audioEn.data !== null) {
					capsule.attributes.audio = capsule.attributes.audioEn;
				}
			}

			return data.data[0];
		},
	);
}
