import { FunctionComponent } from 'react';
import { Marker } from 'react-map-gl';
import styled from 'styled-components';

import Theme from '../../styles/Theme';
import { CapsulesIcons } from '../../models/MapBox';

import { CapsuleIcon } from './capsules/CapsuleIcon';

interface Props {
	longitude: number;
	latitude: number;
	icon: CapsulesIcons;
	color: string;
	selected?: boolean;

	onClick?: () => void;
}

const PinContainer = styled.div<{ color: string }>`
	width: ${(props) => 48 / props.theme.fontSizeBase}rem;
	height: ${(props) => 48 / props.theme.fontSizeBase}rem;
	background: ${(props) => props.color};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
`;

export const MapPin: FunctionComponent<Props> = ({
	icon, color, longitude, latitude, onClick, selected = false,
}: Props) => (
	<Marker
		longitude={longitude}
		latitude={latitude}
		anchor="bottom"
		style={{
			zIndex: selected ? 1 : 0,
		}}

	>
		<PinContainer color={color} onClick={onClick}>
			<CapsuleIcon size={32} color={Theme.white} icon={icon}/>
		</PinContainer>
	</Marker>
);
