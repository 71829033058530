import useSWRImmutable from 'swr/immutable';

import { MAPBOX_KEY } from '../env';
import { rawHttpGet } from '../lib/fetch';
import { DirectionProfiles } from '../models/MapBox';

function formatDirectionsUrl(profile: DirectionProfiles | null, points: Array<Array<number>>) {
	if (profile === null) {
		return null;
	}

	const base = 'https://api.mapbox.com';
	let params = '';

	points.forEach((point, index) => {
		if (index > 0) {
			params += ';';
		}
		params += `${point[0]},${point[1]}`;
	});

	return `${base}/directions/v5/mapbox/${profile}/${params}`;
}

interface MapBoxDirection {
	distance: number;
	duration: number; // Minutes
	// eslint-disable-next-line no-undef
	lineString: GeoJSON.Feature;
}

export const useMapBoxDirections = (
	profile: DirectionProfiles | null,
	points: Array<Array<number>>,
) => useSWRImmutable<MapBoxDirection | null>(
	formatDirectionsUrl(profile, points),
	async (url: string) => {
		if (points.length === 0 || url === null) {
			return null;
		}

		const customUrl = new URL(url);
		const params = {
			steps:        'true',
			geometries:   'geojson',
			access_token: MAPBOX_KEY,
			overview:     'full',
		};

		customUrl.search = new URLSearchParams(params).toString();

		const query = await rawHttpGet(customUrl.href);

		return {
			distance:   query.routes[0].distance,
			duration:   query.routes[0].duration / 60,
			lineString: {
				type:       'Feature',
				properties: {},
				geometry:   {
					type:        'LineString',
					coordinates: query.routes[0].geometry.coordinates,
				},
			},
		} as MapBoxDirection;
	},
);
