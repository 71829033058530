import { memo, useEffect } from 'react';
import { GeoJSONSource, useMap } from 'react-map-gl';

import { useMapBoxDirections } from '../../data/Mapbox.swr';
import { DirectionProfiles } from '../../models/MapBox';
import Theme from '../../styles/Theme';

interface Props {
	profile: DirectionProfiles;
	points: Array<Array<number>>;
	uniqueId: string;
}

// eslint-disable-next-line react/display-name
const Directions = memo<Props>(({ profile, points, uniqueId }: Props) => {
	const { default: map } = useMap();
	const { data: mapBoxDirections } = useMapBoxDirections(profile, points);

	useEffect(() => {
		if (map && mapBoxDirections) {
			if (map.getMap().getSource(uniqueId) && map.getMap().getLayer(uniqueId)) {
				(map.getMap().getSource(uniqueId) as GeoJSONSource).setData(mapBoxDirections.lineString);
				map.getMap().setLayoutProperty(uniqueId, 'visibility', 'visible');
			} else {
				map.getMap().addLayer({
					id:     uniqueId,
					type:   'line',
					source: {
						type: 'geojson',
						data: mapBoxDirections.lineString,
					},
					layout: {
						'line-join': 'round',
						'line-cap':  'round',
					},
					paint: {
						'line-color':   Theme.black,
						'line-width':   4,
						'line-opacity': 0.75,
					},
				});
			}
		}
	}, [map, mapBoxDirections, uniqueId]);

	return null;
});

export default Directions;
