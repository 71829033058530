import { TFunction } from 'next-i18next';

export function convertMinuteToString(
	t: TFunction,
	min: number,
	roundHours = false,
) {
	if (min < 60) {
		return `${Math.round(min)} ${t('common:min')}`;
	}
	const hours = Math.trunc(min / 60);
	const newMin = Math.round(min - hours * 60);

	if (roundHours) {
		if (hours > 1) {
			return `${hours} ${t('common:hours')}`;
		}
		return `${hours} ${t('common:hour')}`;
	}

	if (newMin === 0) {
		return `${hours}${t('common:hourShort')}`;
	}
	if (newMin < 10) {
		return `${hours}${t('common:hourShort')}0${newMin}`;
	}
	return `${hours}${t('common:hourShort')}${newMin}`;
}

export function convertMinuteToHour(min: number) {
	if (min < 60) {
		return min;
	}
	return Math.trunc(min / 60);
}

export function convertSecondsToString(t: TFunction, seconds: number) {
	if (seconds < 60) {
		return `${seconds} ${t('common:secondShort')}`;
	}
	const minutes = Math.trunc(seconds / 60);
	const newSeconds = Math.round(seconds - minutes * 60);

	return `${minutes} ${t('common:min')} ${newSeconds} ${t('common:secondShort')}`;
}
