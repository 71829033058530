import { FunctionComponent, useState } from 'react';
import { Marker } from 'react-map-gl';
import styled from 'styled-components';
import Theme from '../../styles/Theme';

interface Props {
	latitude: number;
	longitude: number;

	nbPoint: number;
	totalPoint: number;

	backgroundColor: string;

	onClick: () => void;
}

const StyledContainer = styled.div<{size: number, backgroundColor: string}>`
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;

	background: ${(props) => props.backgroundColor};
	color: ${(props) => props.theme.white};
	font-size: 5vw;

	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 800;

	cursor: pointer;
`;

export const MapClusterMarker: FunctionComponent<Props> = ({
	latitude, longitude, nbPoint, totalPoint, onClick, backgroundColor,
}: Props) => {
	const [size] = useState(Math.round(30 + (nbPoint / totalPoint) * 30));

	return (
		<Marker
			latitude={latitude}
			longitude={longitude}
		>
			<StyledContainer size={size} onClick={onClick} backgroundColor={backgroundColor}>
				<svg
					width="100%"
					height="100%"
					viewBox={`0 0 ${size} ${size}`}
					preserveAspectRatio="xMinYMid meet"
				>
					<text
						textAnchor="middle"
						alignmentBaseline="middle"
						x={size / 2}
						y={size / 2 + 2}
						fontSize="25"
						fill={Theme.white}
					>{nbPoint}</text>
				</svg>
			</StyledContainer>
		</Marker>
	);
};
