import { Feature, Point } from 'geojson';

import { Capsule } from '../models/Capsule';

export function convertCapsulesToFeatures(capsules: Capsule[]):Feature<Point, Capsule>[] {
	return capsules.map((capsule) => (
		{
			type:     'Feature',
			geometry: {
				type:        'Point',
				coordinates: [capsule.attributes.longitude, capsule.attributes.latitude], // [lng, lat]
			},
			properties: capsule,
		}
	));
}
